"use client";

import { UserProfile, Workspace, getPermissions, useAuth } from ".";
import React, { createContext, useEffect, useState } from "react";
import { InstantObject, transact } from "./core";


import { WorkspaceRoles, formatStorage } from "@palette.tools/model";


export function getWorkspaceRole(workspace: Workspace, profile: UserProfile) {
  if (workspace.is_role("admin", profile.id)) return WorkspaceRoles.admin;
  return WorkspaceRoles.member;
}

export interface FileValidation {
  isValid: boolean,
  reason?: string,
}

export function validateWorkspaceFile(workspace: Workspace | null, f: File | null): FileValidation {
  if (!f) {
    return { isValid: false, reason: "No file selected." };
  }
  if (!workspace?.data.storage_max_file_size || !workspace?.data.storage_limit || typeof(workspace?.data.storage_size) !== "number") {
    return { isValid: false, reason: "Workspace is not set up for file uploads." };
  }
  else if (f.size > workspace.data.storage_max_file_size) {
    return { isValid: false, reason: `File is too large. Max file size is ${formatStorage(workspace.data.storage_max_file_size)}.` };
  }
  else if (f.size > workspace.data.storage_limit - workspace.data.storage_size) {
    let bytesLeft = workspace.data.storage_limit - workspace.data.storage_size;
    bytesLeft = bytesLeft > 0 ? bytesLeft : 0;
    return { isValid: false, reason: `${formatStorage(bytesLeft)} left in workspace. File is ${formatStorage(f.size)}` };
  }
  return { isValid: true }
}


export const WorkspaceAuthContext = createContext<ReturnType<typeof useAuth>>({
  profile: undefined,
  isLoading: true,
  isPreloaded: false,
  error: undefined,
  signOut: async () => {},
  multiServiceAuth: null,
});


interface AuthProviderProps {
  children: React.ReactNode;
}


export const useWorkspaceAuth = () => {
  return React.useContext(WorkspaceAuthContext);
}


const SESSION_STORAGE_KEY = "palette_last_workspace_visited";


export const WorkspaceAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  const workspace = Workspace.useInContext();
  const authResult = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!!authResult.profile?.id && !!workspace?.id) {
      const { isWorkspaceMember } = getPermissions({ workspace, profile: authResult.profile });
      if (!isWorkspaceMember) {
        // Redirect to access denied page
        window.location.replace("/access-denied");
        return;
      }
      setIsLoaded(true);
      transact(
        ...UserProfile.update(authResult.profile.id, { last_workspace_visited: workspace.id }),
      );
      sessionStorage.setItem(SESSION_STORAGE_KEY, workspace.id);
    }

  }, [!!authResult.profile, !!workspace?.id]);


  const wrappedComponents = <WorkspaceAuthContext.Provider value={{
    ...authResult,
  }}>{isLoaded ? children : null}</WorkspaceAuthContext.Provider>

  return wrappedComponents;

};


export const useMostRecentWorkspace = (props?: { preloadedData?: InstantObject }) => {
  const { profile: loadedProfile } = useAuth();

  const [ profileLoaded, setProfileLoaded ] = useState(false);
  useEffect(() => {
    setProfileLoaded(true);
  }, [!!loadedProfile]);

  const overrideLastWorkspaceVisited = sessionStorage.getItem(SESSION_STORAGE_KEY) || loadedProfile?.data.last_workspace_visited || "never";

  const [ workspaces, { isLoading: isLoadingWorkspaces } ] = Workspace.useCustom({
    $: {
      where: {
        id: overrideLastWorkspaceVisited,
      }
    },
    role__workspace__admin: {
      profile: {},
    },
    profile: {},
    project: { profile: {} },
    group__project: { project: { profile: {} } },

  });

  const workspace = workspaces?.[0] || null;

  return { workspace, id: overrideLastWorkspaceVisited === "never" ? undefined : overrideLastWorkspaceVisited, isLoading: isLoadingWorkspaces || !profileLoaded };
}
