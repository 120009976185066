export * from "./Crop";
export * from "./FileEntry";
export * from "./ProjectRoles";
export * from "./Profile";
export * from "./Task";
export * from "./Thumbnail";
export * from "./video";
export * from "./Workspace";
export * from "./WorkspaceRoles";
export * from "./ViewMode";


export class UnauthorizedAccessError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedAccessError';
  }
}


export class SomethingWentWrongError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SomethingWentWrongError';
  }
}


export class UserInformedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserInformedError';
  }
}
