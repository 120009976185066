

export type ViewModeKey = 't' | 'f' | 'c';

export const ViewModes: readonly {
	key: ViewModeKey,
	label: string,
}[] = [
	{
		key: 't',
		label: 'Table',
	},
	{
		key: 'f',
		label: 'Files',
	},
	{
		key: 'c',
		label: 'Chat',
	},
];

export const ViewModeKeys = ViewModes.map(mode => mode.key) as ViewModeKey[];

export const ViewModeLabels: Record<ViewModeKey, string> = ViewModes.reduce((acc, mode) => {
	acc[mode.key] = mode.label;
	return acc;
}, {} as Record<ViewModeKey, string>);