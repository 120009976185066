import { useAuth } from "./auth";
import { Asset, Category, Comment, Entity, Phase, Project, Task, UserProfile, Workspace } from "./model";


export interface PermissionsProps {
  profile?: UserProfile | null,
  workspace?: Workspace | null,
  project?: Project | null,
  category?: Category | null,
  asset?: Asset | null,
  task?: Task | null,
  phase?: Phase | null,
  comment?: Comment | null,
}

export function getPermissions(props: PermissionsProps) {
  const {
    profile,
    workspace,
    project,
    category,
    asset,
    task,
    phase,
    comment,
  } = props;

  const isWorkspaceAdmin = !!profile && workspace?.is_role("admin", profile.id) || false;
  const isWorkspaceMember = !!profile && !!workspace?.links.profile?.find(x => x.id === profile.id) || false;
  const isProjectAdmin = !!profile && project?.is_role("admin", profile.id) || false;
  const isProjectReviewer = !!profile && project?.is_role("reviewer", profile.id) || false;
  const isProjectEditor = !!profile && project?.is_role("editor", profile.id) || false;
  const isProjectMember = !!profile && !!project?.links.profile?.find(x => x.id === profile.id) || false;

  const canUploadWithinWorkspace = isWorkspaceMember
    && workspace?.data.storage_max_file_size !== undefined
    && workspace?.data.storage_max_file_size !== null
    && workspace?.data.storage_limit !== undefined
    && workspace?.data.storage_limit !== null
    && workspace?.data.storage_size !== undefined
    && workspace?.data.storage_size !== null
    && workspace.data.storage_size < workspace.data.storage_limit;

  const canUploadWithinProject = (isProjectMember || isWorkspaceAdmin) && canUploadWithinWorkspace;

  return {

    isWorkspaceAdmin,
    isWorkspaceMember,
    isProjectAdmin,
    isProjectReviewer,
    isProjectEditor,
    isProjectMember,

    canCreateWorkspace: isWorkspaceAdmin || false,
    canEditWorkspace: !!workspace && isWorkspaceAdmin || false,
    canEditWithinWorkspace: !!workspace && isWorkspaceMember || false,
    canDeleteWorkspace: !!workspace && isWorkspaceAdmin || false,
    canManageWorkspaceUsers: !!workspace && isWorkspaceAdmin || false,
    canUploadWithinWorkspace,

    canCreateProject: !!workspace && isWorkspaceAdmin || false,
    canEditProject: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,
    canEditWithinProject: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteProject: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,
    canManageProjectUsers: !!project && (isWorkspaceAdmin || isProjectAdmin) || false,
    canUploadWithinProject,

    canCreateCategory: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditCategory: !!category && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteCategory: !!category && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateAsset: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditAsset: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteAsset: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateTask: !!asset && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditTask: !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteTask: !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreatePhase: !!project && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditPhase: !!phase && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeletePhase: !!phase && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

    canCreateComment: !!profile && isWorkspaceMember || false,
    canEditComment: !!profile && !!comment && comment.data.created_by === profile?.id || false,
    canDeleteComment: !!profile && !!comment && comment.data.created_by === profile?.id || isWorkspaceAdmin || isProjectAdmin || false,

    canCreateFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canEditFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,
    canDeleteFile: !!profile && !!task && (isWorkspaceAdmin || isProjectAdmin || isProjectEditor || isProjectReviewer) || false,

  }
}


export function usePermissions(props: PermissionsProps) {

  const { profile } = useAuth();
  const workspace = Workspace.useInContext() || props.workspace;
  const project = Project.useInContext() || props.project;
  const category = Category.useInContext() || props.category;
  const asset = Asset.useInContext() || props.asset;
  const task = Task.useInContext() || props.task;
  const phase = Phase.useInContext() || props.phase;
  const comment = Comment.useInContext() || props.comment;

  return {...getPermissions({
      profile,
      workspace,
      project,
      category,
      asset,
      task,
      phase,
      comment,
    }),
    context: {...props, profile},
  }

}

export function getCanDelete(props: PermissionsProps, deletables: Entity[]) {

  const { canDeleteWorkspace, canDeleteProject, canEditWithinProject, canEditWithinWorkspace } = getPermissions(props);

  // Must be in a workspace context.
  const workspace = props.workspace;
  if (!workspace) {
    return { canDelete: false, nonDeletables: deletables.map(d => [d, 'Not in a workspace context']) };
  }

  const project = props.project;

  // In a workspace context, but not in a project context, must be able to edit within the workspace.
  if (!project && !canEditWithinWorkspace) {
    return { canDelete: false, nonDeletables: deletables.map(d => [d, 'Cannot delete within workspace']) };
  }

  // In a project context, must be able to edit within the project or workspace.
  if (!!project && (!canEditWithinProject && !canEditWithinWorkspace)) {
    return { canDelete: false, nonDeletables: deletables.map(d => [d, 'Cannot delete within project']) };
  }

  let canDelete = true;
  let nonDeletables: [Entity, string][] = [];

  for (const deletable of deletables) {
    if (!!project) {

      // Check if the deletable is the project itself.
      if (deletable.id === project.id) {
        if (!canDeleteProject) {
          canDelete = false;
          nonDeletables.push([deletable, 'Cannot delete project']);
        }
      }
      // else if(
      //   // Check if the deletable is in the project's loaded links.
      //   !(deletable.key in project.links && (project.links[deletable.key as keyof typeof project.links]?.some(p => p.id === deletable.id) ?? false))

      //   // Check if the project is in the deletable's loaded links.
      //   && !('project' in deletable.links && (deletable.links.project?.some(p => p.id === project.id) ?? false))
      // ) {
      //   canDelete = false;
      //   nonDeletables.push([deletable, 'Entity outside of project']);
      // }

    }

    // Check if the deletable is the workspace itself.
    else if (deletable.id === workspace.id){
      if (!canDeleteWorkspace) {
        canDelete = false;
        nonDeletables.push([deletable, 'Cannot delete workspace']);
      }
    }
    // else if (
    //   // Check if the deletable is in the workspace's loaded links.
    //   !(deletable.key in workspace.links && (workspace.links[deletable.key as keyof typeof workspace.links]?.some(p => p.id === deletable.id) ?? false))

    //   // Check if the workspace is in the deletable's loaded links.
    //   && !('workspace' in deletable.links && (deletable.links.workspace?.some(p => p.id === workspace.id) ?? false))
    // ) {
    //   canDelete = false;
    //   nonDeletables.push([deletable, 'Entity outside of workspace']);
    // }
  }

  return { canDelete, nonDeletables };

}

export const useCanDelete = (props: { context: PermissionsProps, deletables: Entity[]}) => {
  const { canDelete, nonDeletables } = getCanDelete(props.context, props.deletables.filter(d => !!d));
  return { canDelete, nonDeletables };
}

export type PermissionsContext = ReturnType<typeof usePermissions>['context'];